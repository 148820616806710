import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router'
import Main from '../views/Main.vue'

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main,
    redirect: '/newApp',
    children: [
      {
        path: '/oldApp',
        name: 'OldApp',
        component: () => import('../views/OldApp'),
      },
      {
        path: '/newApp',
        name: 'NewApp',
        component: () => import('../views/NewApp'),
      },
      {
        path: '/nft',
        name: 'Nft',
        component: () => import('../views/Nft'),
      },
      {
        path: '/productDescription',
        name: 'ProductDescription',
        component: () => import('../views/Nft/ProductDescription'),
      },
      {
        path: '/personal',
        name: 'Personal',
        component: () => import('../views/Nft/Personal'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
})

export default router
