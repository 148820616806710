module.exports = {
  dapp: {
    userYield: {
      totalArt: 'ART总量',
      totalPower: '全网战力',
      totalRevenue: '我的总收益',
      totalStrength: '我的总战力',
      personalPower: '个人战力',
      kineticEnergy: '动能战力',
      starWarsforce: '星球战力',
      invitedNum: '邀请数量',
      teamNum: '团队数量',
      currentCirculation: '本轮释放',
      nextCurrentCirculation: '下轮释放',
      roundCountdown: '轮倒计时',
    },
    knight: {
      KnightJourney: '骑士征程',
      authorization: '授权',
      harvest: '收获',
      affirm: '确认',
      invitationBenefits: '点对点邀请收益',
      nodeReturns: '节点收益',
    },
    invitation: {
      myInvitation: '我的邀请',
      inviteFriends: '邀请好友',
      myInviter: '我的邀请人',
      contractadres: '合约地址',
      address: '地址',
      militaryCapability: '战力',
      time: '时间',
    },
  },
  nft: {
    main: {
      issuance: '发行的NFT',
      market: 'NFT市场',
    },
    issuance: {
      noMore: '没有更多了',
      requestFailed: '请求失败，点击重新加载',
      margin: '余量',
      loading: '加载中...',
      quantity: '购买数量',
      nftName: 'NFT名称',
      balance: '余额',
      price: '商品总额',
      close: '取消',
      confirm: '确认',
      confirmationPayment: '购买确认支付',
    },
    market: {
      price: '价格',
    },
    personal: {
      main: {
        personalHeld: '持有的NFT',
        personalSale: '出售的NFT',
      },
      personalHeld: {
        sell: '出售',
        importPrice: '输入价格',
      },
      personalSale: {
        cancelTheSale: '取消出售',
      },
    },
    description: {
      linkPurse: '链接钱包',
      buy: '购买',
      owner: '持有人',
      network: '链网络',
      rule: '规则',
      rule1: '支持KNT进行交易购买',
      rule2: 'NFT在出售挂单的过程中,不改变用户的所有权',
      rule3: '手续费为成交金额的3%',
      purchases: '总量',
      surplus: '剩余数量',
    },
    top: {
      return: '返回',
      myNft: '我的NFT',
      backHomepage: '返回首页',
    },
  },
  toast: {
    fail: '失败',
    metaMask: 'MetaMask已激活,如未打开请手动打开并链接钱包',
    win: '成功',
    gtcAgin: '您已经质押过gtc了',
    GCTLess: '您的gtc不足',
    moneyCover: '您的钱不足,无法支付手续费',
    yieldZero: '收益为0,无法收获',
    authorize: '请在钱包里同意授权...',
    notInvitedFriends: '您没有邀请过好友,无法收获',
    reality: '实际:',
    default: '默认:',
    copyInvitation: '复制邀请链接成功',
    copyAddress: '复制合约地址成功',
    positiveInteger: '请输入正整数的数量',
    balance: '余额不够',
    exceeds: '超过商品剩余数量',
    authorization: '正在授权购买此商品...',
    loading: '正在购买...',
    authorizations: '正在授权取消此商品...',
    loading2: '正在出售...',
    decimalFraction: '小数不能超过6位',
    winPrice: '请输入正确价格',
    voiceCanceling: '正在取消',
  },
}
