import { createI18n } from 'vue-i18n'

import zh from './zh'
import en from './en'

// 默认语言 -
const default_lang = 'zh'
const i18n = createI18n({
  locale: default_lang,
  legacy: false, // 让 setup 函数可以通过 t 访问
  globalInjection: true, // 让 template 可以像 vue2 那样使用 $t 来访问
  locale: 'zh-cn',
  fallbackLocale: 'en-us',
  messages: {
    'zh-cn': zh,
    'en-us': en,
  },
})

export default i18n
