<template>
  <div @click="goDapp" class="back" v-if="!isshow">
    {{ $t('nft.top.return') }}
  </div>
  <div class="top" :style="bgColor">
    <div class="content" v-if="isshow">
      <div class="iconNewapp" v-if="isNewapp"></div>
      <div class="myNft" @click="goMyNft" v-if="isShow">
        {{ $t('nft.top.myNft') }}
      </div>
      <div class="goNft" @click="goNft" v-if="isBack">
        {{ $t('nft.top.backHomepage') }}
      </div>
      <div
        class="goNft"
        v-if="
          isDetails &&
          (identify == 'project' || identify == 'market') &&
          cancel != 'cancel'
        "
        @click="goBack"
      >
        {{ $t('nft.top.return') }}
      </div>
      <div
        class="goNft"
        v-if="isDetails && (identify == 'held' || cancel == 'cancel')"
        @click="goBack"
      >
        {{ $t('nft.top.return') }}
      </div>
      <div class="address">{{ userAddress }}</div>
      <van-popover
        v-model:show="showPopover"
        theme="dark"
        :actions="actions"
        placement="bottom-end"
        @select="onSelect"
      >
        <template #reference>
          <div class="zhOrEn"></div>
        </template>
      </van-popover>
    </div>
  </div>
</template>

<script>
  import { formatAddress } from '@/utils/format.js'
  import { useRouter, useRoute } from 'vue-router'
  import { onMounted, reactive, ref } from 'vue'
  import { onBeforeRouteUpdate, onBeforeRouteLeave } from 'vue-router'
  import { Toast, Popover } from 'vant'
  import { useI18n } from 'vue-i18n'
  export default {
    name: 'Top',
    setup() {
      const { locale } = useI18n()
      let userAddress = ref(formatAddress(window.ethereum.selectedAddress))
      if (!window.ethereum.selectedAddress) {
        setTimeout(() => {
          userAddress.value = formatAddress(window.ethereum.selectedAddress)
        }, 1000)
      }
      const router = useRouter()
      const route = useRoute()

      let identify = ref(route.query.identify)
      let cancel = ref(route.query.cancel)
      let goDapp = () => {
        router.push({ name: 'NewApp' })
      }
      //#region  控制头部的按钮的隐藏和显示
      let path = ['/oldApp']
      let isshow = ref()
      let isDetails = ref()

      onBeforeRouteUpdate((to, from) => {
        identify.value = to.query.identify
        cancel.value = to.query.cancel
      })
      let goMyNft = () => {
        router.push({ name: 'Personal' })
      }
      let goBack = () => {
        router.go(-1)
      }
      let goNft = () => {
        router.push({ name: 'Nft' })
      }
      let bgColor = reactive({
        background: 'rgba(0, 0, 0, 0)',
      })
      let isShow = ref(true)
      let isNewapp = ref(true)
      let isBack = ref(false)
      onBeforeRouteUpdate((to, from) => {
        if (['NftDetail', 'Personal', 'ProductDescription'].includes(to.name)) {
          isNewapp.value = false
        } else {
          isNewapp.value = true
        }
        if (['NftDetail', 'Personal'].includes(to.name)) {
          isBack.value = true
        } else {
          isBack.value = false
        }
        if (['Nft'].includes(to.name)) {
          isShow.value = true
        } else {
          isShow.value = false
        }
        if (['OldApp'].includes(to.name)) {
          isshow.value = false
        } else {
          isshow.value = true
        }
        if (['DroductDescription'].includes(to.name)) {
          isDetails.value = false
        } else {
          isDetails.value = true
        }
      })
      //初始化就判断一次
      onMounted(() => {
        if (path.includes(route.path)) {
          isshow.value = false
        } else {
          isshow.value = true
        }
        if (['/productDescription'].includes(route.path)) {
          isDetails.value = true
        } else {
          isDetails.value = false
        }
      })
      onMounted(() => {
        if (['/nftDetail', '/personal'].includes(route.path)) {
          isBack.value = true
        } else {
          isBack.value = false
        }
        if (
          ['/nftDetail', '/personal', '/productDescription'].includes(
            route.path
          )
        ) {
          isNewapp.value = false
        } else {
          isNewapp.value = true
        }
        if (['/nft'].includes(route.path)) {
          isShow.value = true
        } else {
          isShow.value = false
        }
        window.addEventListener('scroll', () => {
          let top = document.documentElement.scrollTop / 800
          if (top > 0.5) {
            top = 0.5
          } else if (top < 0) {
            top = 0
          }
          bgColor.background = 'rgba(0, 0, 0,' + top + ')'
        })
      })
      //#endregion
      const showPopover = ref(false)
      const actions = [{ text: '中文' }, { text: 'English' }]
      const onSelect = (action) => {
        if (action.text == '中文') {
          locale.value = 'zh-cn'
          Toast('中文')
        } else if (action.text == 'English') {
          locale.value = 'en-us'
          Toast('English')
        }
      }

      return {
        goMyNft,
        userAddress,
        bgColor,
        isNewapp,
        goNft,
        isShow,
        isBack,
        isshow,
        goDapp,
        actions,
        showPopover,
        onSelect,
        isDetails,
        identify,
        cancel,
        goBack,
      }
    },
    components: {
      [Popover.name]: Popover,
    },
  }
</script>

<style lang="scss" scoped>
  .top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 999;

    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 1.2821rem;
      padding: 0.5897rem;

      .iconNewapp {
        width: 0.9231rem;
        height: 1.0256rem;
        background: url('./images/logoNewapp@2x.png') center no-repeat;
        background-size: 100%;
      }

      .myNft {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 0.359rem;
        height: 0.8205rem;
      }

      .goNft {
        color: #fff;
        font-size: 0.359rem;
      }

      .address {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3.0769rem;
        height: 0.8205rem;
        background: rgba($color: #fff, $alpha: 0.1);
        border-radius: 0.2051rem;
        color: #fff;
        font-size: 0.359rem;
      }
    }
  }

  .back {
    position: fixed;
    top: 16px;
    left: 8px;
    text-align: center;
    height: 26px;
    background-color: #000;
    float: left;
    color: #fff;
    line-height: 26px;
    font-size: 0.4103rem;
  }

  .zhOrEn {
    width: 0.5128rem;
    height: 0.5128rem;
    background: url('./images/globe@2x.png') center no-repeat;
    background-size: 0.5128rem;
  }
</style>
