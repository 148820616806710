import Web3 from 'web3'
import BigNumber from 'bignumber.js'
export const formatAddress = (address) => {
  return address && address.slice(0, 6) + '...' + address.slice(-6)
}
const utils = new Web3().utils
const UNIT = 'mwei'
export function toVal(val) {
  val = typeof val === 'number' ? val.toString() : val
  return val && Number(utils.toWei(val, UNIT))
}
const web3js = new Web3()
BigNumber.config({ EXPONENTIAL_AT: [-20, 40] })
export const fromWei = (value, decimals = 18) => {
  return new BigNumber(value).dividedBy(new BigNumber(10).pow(decimals))
}
export const formatAmount = (value, decimals = 18, fixed = 6) => {
  return new BigNumber(
    fromWei(value, decimals).toFixed(fixed === -1 ? null : fixed, 1)
  ).toString()
}
