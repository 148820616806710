import { createStore } from 'vuex'

export default createStore({
  state: {
    refreshData: false, //控制newApp是否刷新数据
    inviterAddress: '', //newapp的邀请人地址
    isShow: true, //控制nft市场和项目的显示隐藏
    isFinished: false, //控制nft列表是否已经达到最大值
    isDetail: false, //控制detail列表是否已经达到最大值
    sallList: false, //控制Mysall列表是否已经达到最大值
    heldList: false, //控制Myheld列表是否已经达到最大值
  },
  mutations: {
    REFRESH(state) {
      state.refreshData = !state.refreshData
    },
    INVITER(state, value) {
      state.inviterAddress = value
    },
    CHANGESHOW(state, value) {
      state.isShow = value
    },
    CHANGEFINISHED(state, value) {
      state.isFinished = value
    },
    CHANGDETAIL(state, value) {
      state.isDetail = value
    },
    CHANGESALE(state, value) {
      state.sallList = value
    },
    CHANGDEHELD(state, value) {
      state.heldList = value
    },
  },
  actions: {},
  modules: {},
})
