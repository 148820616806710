<template>
  <Top />
  <div class="contant">
    <router-view></router-view>
  </div>
  <Bottom />
</template>

<script>
import Bottom from '../components/Bottom'
import Top from '../components/Top'
export default {
  name: 'Main',
  components: { Bottom, Top },
  setup() {
    //监听钱包切换
    window.ethereum.on('accountsChanged', function (accounts) {
      window.location.reload()
    })
    //监听链网络改变
    window.ethereum.on('chainChanged', () => {
      window.location.reload()
    })
  },
}
</script>

<style lang="scss" scoped>
.contant {
  width: 100%;
  background-color: #091023;
}
</style>
