<template>
  <div class="bottom" v-if="isShow">
    <div class="box">
      <!--<router-link active-class="pick" :to="{ name: 'OldApp', query: { f: queryF } }">KNT</router-link>-->
<!--      <a href="http://www.hiknt.com/">KNT</a>-->
      <router-link active-class="pick" :to="{ name: 'NewApp', query: { f: queryF } }">ART</router-link>
      <!--<router-link active-class="pick" :to="{ name: 'Nft', query: { f: queryF } }">NFT</router-link>-->
      <a href="https://dex1.web3dev.vip/">SWAP</a>
    </div>
  </div>
</template>
<script>
import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router'
import { computed, onMounted, ref } from 'vue'
import { Toast } from 'vant'
export default {
  name: 'Bottom',
  setup(props) {
    let isShow = ref(true)
    const route = useRoute()
    let queryF = computed(() => {
      if (route.query.f) {
        return route.query.f
      }
    })

    let path = ['/personal', '/nftDetail', '/productDescription', '/oldApp']
    onMounted(() => {
      if (path.includes(route.path)) {
        isShow.value = false
      } else {
        isShow.value = true
      }
    })
    onBeforeRouteUpdate((to, from) => {
      if (
        ['NftDetail', 'Personal', 'ProductDescription', 'OldApp'].includes(
          to.name
        )
      ) {
        isShow.value = false
      } else {
        isShow.value = true
      }
    })
    return {
      queryF,
      isShow,
    }
  },
}
</script>
>

<style lang="scss" scoped>
.box {
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 9.2308rem;
  height: 1.2308rem;
  border-radius: 0.3077rem;
  background: #151e34;

  a {
    display: flex;
    justify-content: center;
    height: 1.0256rem;
    width: 2.0513rem;
    align-items: center;
    font-size: 0.3077rem;
    color: rgb(236, 235, 226);
    border-radius: 0.2051rem;
  }

  .pick {
    background-color: #2b3341;
    color: #edb43a;
  }
}

.bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: rgba($color: #000000, $alpha: 0.1);
}
</style>
