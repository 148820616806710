module.exports = {
  dapp: {
    userYield: {
      totalArt: 'Total art',
      totalPower: 'Total power',
      totalRevenue: ' Total revenue',
      totalStrength: 'Total strength',
      personalPower: 'Personal power',
      kineticEnergy: 'Kinetic Energy',
      starWarsforce: 'Star warsforce',
      invitedNum: 'Invited number',
      teamNum: 'Team number',
      currentCirculation: 'The current release',
      nextCurrentCirculation: 'Next Current Circulation',
      roundCountdown: 'Round Countdown',
    },
    knight: {
      KnightJourney: 'Knight Journey',
      authorization: 'Authorization',
      harvest: 'Harvest',
      affirm: 'Affirm',
      invitationBenefits: 'Invitation Benefits',
      nodeReturns: 'Node Returns',
    },
    invitation: {
      myInvitation: 'My Invitation',
      inviteFriends: 'Invite Friends',
      myInviter: 'my Inviter',
      contractadres: 'contractadres',
      address: 'address',
      militaryCapability: 'Military Capability',
      time: 'time',
    },
  },
  nft: {
    main: {
      issuance: 'NFT issuance',
      market: 'NFT market',
    },
    issuance: {
      noMore: 'no more',
      requestFailed: 'request failed',
      margin: 'margin',
      loading: 'loading...',
      quantity: 'quantity',
      nftName: 'nft name',
      balance: 'balance',
      price: 'price',
      close: 'close',
      confirm: 'confirm',
      confirmationPayment: 'confirmation payment',
    },
    market: {
      price: 'price',
    },
    personal: {
      main: {
        personalHeld: 'Held NFT',
        personalSale: 'Sale NFT',
      },
      personalHeld: {
        sell: 'sell',
        importPrice: 'import price',
      },
      personalSale: {
        cancelTheSale: 'Cancel The Sale',
      },
    },
    description: {
      linkPurse: 'Link Purse',
      buy: 'Buy',
      owner: 'owner',
      network: 'network',
      rule: 'rule',
      rule1: 'Support KNT for transaction purchase',
      rule2:
        'NFT does not change the ownership of the customer in the process of selling the receipt',
      rule3: 'The commission is 3% of the transaction amount',
      purchases: 'purchases',
      surplus: 'surplus',
    },
    top: {
      return: 'Go Back',
      myNft: 'My Nft',
      backHomepage: 'Back Home',
    },
  },
  toast: {
    fail: 'fail',
    metaMask:
      'MetaMask has been activated, if not open, please manually open and link the wallet',
    win: 'win',
    gtcAgin: 'You have already pledged GTC and cannot pledge again',
    GCTLess: 'Your GCT is less than 1',
    moneyCover: "You don't have enough money to cover the commission",
    yieldZero: 'Yield is zero, no harvest.',
    authorize: 'Please agree to authorize in wallet...',
    notInvitedFriends: 'You have not invited friends, can not harvest',
    reality: 'reality:',
    default: 'default:',
    copyInvitation: 'Replication invitation link succeeded',
    copyAddress: 'Succeeded in copying the contract address',
    positiveInteger: 'Please enter a positive integer number',
    balance: 'The balance is not enough',
    exceeds: 'Exceeds the quantity of goods remaining',
    authorization: 'Authorization to purchase this product is in progress...',
    loading: 'Buying unit',
    authorizations: 'This product is being authorized for sale...',
    loading2: 'on sale...',
    decimalFraction: 'The decimal cannot be more than six digits',
    winPrice: 'Please enter the correct price',
    voiceCanceling: 'My Voice Canceling',
  },
}
